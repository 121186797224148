<template>
  <div class="card mb-3 h-100 card-link">
  <div class="image-container">
    <router-link v-if="imageSrc" :to="{ name: 'RecipeDetail', params: { id: item.id } }">
      <img
        :src="imageSrc"
        class="card-img-top"
        :alt="item.title"
        :width="250"
        :height="250"
        loading="lazy"
        :class="{ 'blurred-image': !imageSrc }"
      >
    </router-link>
    <i
      v-if="isAuthenticated"
      :class="{
        'bi bi-bookmark-plus-fill add-to-dishes-icon': !isAdded,
        'bi bi-bookmark-plus-fill add-to-dishes-icon added': isAdded
      }"
      @click.stop="toggleDish"
    ></i>
  </div>
    <router-link :to="{ name: 'RecipeDetail', params: { id: item.id } }" class="card-body-link">
      <div class="card-body d-flex flex-column" :class="{ 'no-image': !imageSrc }">
        <div class="d-flex justify-content-between align-items-center mb-2">
          <div class="d-flex align-items-center">
            <span v-if="item.method && item.method.includes('Ofen')" class="icon">🍳</span>
            <span v-if="item.method && item.method.includes('Heißluftfritteuse')" class="icon">🍟</span>
            <span v-if="item.method && item.method.includes('Herd')" class="icon">🍳</span>
            <p class="card-text">
              <small class="text-muted">von {{ item.username }}</small>
            </p>
          </div>
          <div>
            <span v-for="star in 5" :key="star" class="star" :class="{ filled: star <= item.evaluation }">★</span>
          </div>
        </div>
        <h5 class="card-title">{{ item.title }}</h5>
        <p class="card-text ingredients">
          <span v-if="item.ingredients">
            {{ item.ingredients.map(ingredient => ingredient.name).join(', ') }}
          </span>
        </p>
      </div>
   </router-link>
  </div>
</template>



<script>
import axios from 'axios';

import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      imageSrc: null,
      isAdded: false
    };
  },
  async mounted() {
    const token = getCookie('token');
    if (token) {
      this.isAuthenticated = true;
      await this.checkIfAdded(); // Check if the item is already added
    }
    if (this.item.images && this.item.images.length > 0) {
      try {
        const imageBlob = await this.fetchImageFromKV(this.item.images[0]);
        this.imageSrc = URL.createObjectURL(imageBlob);
      } catch (error) {
        console.error('Error fetching image from KV:', error);
      }
    }
  },
  methods: {
    async checkIfAdded() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        console.log("username " + username);

        const response = await axios.get(`/api/dishesItem/?id=${username}`);
        const dishes = response.data;

        this.isAdded = dishes.some(dish => dish.title === this.item.title);

      } catch (error) {
        console.error('Error checking if dish is added:', error);
      }
    },
    async toggleDish(event) {
      event.stopPropagation(); // Prevent the click from propagating to the parent elements
      
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        if (this.isAdded) {
          // Remove the dish if it's already added
          const response = await axios.get(`/api/dishesItem/?id=${username}`);
          const dishes = response.data;
          const dish = dishes.find(dish => dish.title === this.item.title);

          if (dish) {
            await axios.delete(`/api/dishesItem/?userid=${username}&title=${dish.title}`);
            this.isAdded = false;
            this.$emit('removeDishes', dish.title);
            alert('Rezept aus den Gerichten entfernt!');
          }

        } else {
          // Add the dish if it's not already added
          const dishData = {
            title: this.item.title,
            ingredients: this.item.ingredients.map(ingredient => ({
              name: ingredient.name,
              amount: `${ingredient.amount} ${ingredient.unit}`
            })),
            amount: 1,
            id: Date.now().toString()
          };

          await axios.post(`/api/dishesItem/?id=${username}`, dishData);

          this.isAdded = true;
          this.$emit('toDishes', dishData);
          alert('Rezept zu Gerichten hinzugefügt!');
        }
      } catch (error) {
        console.error('Error toggling dish:', error);
      }
    },
    async fetchImageFromKV(kvUrl) {
      const kvImageId = kvUrl.split('kv://')[1]; // Extract the image ID from the URL
      const currentUrl = window.location.origin; // Get the current origin (protocol + hostname + port)

      // Define the cache key
      const cacheKey = `${currentUrl}/api/kv?id=${kvImageId}`;
      
      try {
        // Open the cache
        const cache = await caches.open('image-cache');
        
        // Check if the image is already cached
        let cachedResponse = await cache.match(cacheKey);
        
        if (cachedResponse) {
          return cachedResponse.blob();
        } else {
          const response = await fetch(cacheKey);

          // Check for a valid response
          if (!response.ok) {
            throw new Error(`Failed to fetch image: ${response.statusText}`);
          }
          
          // Clone the response for caching and consumption
          const responseClone = response.clone();
          cache.put(cacheKey, responseClone);
          
          return response.blob();
        }
      } catch (error) {
        console.error(`Error fetching image ${kvImageId}:`, error);
      }
    },
    async addToDishes(event) {
      event.stopPropagation(); // Prevent the click from propagating to the parent elements
      if (this.isAdded) {
        alert('This recipe is already added to your dishes.');
        return; // Prevent adding the same dish again
      }
      
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const dishData = {
          title: this.item.title,
          ingredients: this.item.ingredients.map(ingredient => ({
            name: ingredient.name,
            amount: `${ingredient.amount} ${ingredient.unit}`
          })),
          amount: 1,
          id: Date.now().toString()
        };

        await axios.post(`/api/dishesItem/?id=${username}`, dishData);

        // Change the icon appearance to indicate the recipe was added
        this.isAdded = true;
        alert('Rezept zu Gerichten hinzugefügt!');
      } catch (error) {
        console.error('Error adding dish:', error);
      }
    },
  }
}
</script>

<style scoped>
.card-link {
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color consistent */
}

.card-body-link{
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Keep the text color consistent */
}

.card-img-top {
  cursor: pointer;
  width: 100%; /* Ensure full width */
  height: 250px; /* Set a fixed height */
  object-fit: cover; /* Ensure the image covers the container */
  display: block; /* To prevent any margin collapse issues */
}

.card-body.no-image {
  min-height: 200px; /* Set a minimum height for the card body when there is no image */
}

.star {
  color: #ccc;
  font-size: 20px;
}

.star.filled {
  color: #ac8d7e; /* Same color as the button */
}

.icon {
  font-size: 20px;
  margin-right: 5px;
}

.icon-image {
  width: 20px;  /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  margin-right: 5px; /* Space between the icon and text */
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.card-title {
  margin-bottom: 0; /* Remove bottom margin for more compact title */
}

.ingredients {
  color: #6c757d; /* Set the text color to grey */
  font-size: 0.9em; /* Make the text smaller */
}

.add-to-dishes-icon {
  position: absolute;
  top: -9px;
  left: 0px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 30px;
  color: #e9e9e9;
}

.add-to-dishes-icon.added {
  color: #544739;
}
.blurred-image {
  filter: blur(10px);
  transition: filter 0.3s;
}
</style>