<template>
  <div class="recipe-detail">
    <div class="carousel">
      <swiper
        :space-between="10"
        :autoplay="{ delay: 2000, disableOnInteraction: false }"
        :pagination="{ clickable: true }"
        class="mySwiper"
        ref="mySwiper"
      >
        <swiper-slide v-for="(image, index) in imageSrcs" :key="index">
          <img :src="image" class="carousel-img">
        </swiper-slide>
      </swiper>
    </div>
    <h1>{{ recipe.title }}</h1>
    <div class="d-flex justify-content-between">
      <div class="method">
        <span v-if="recipe.method && recipe.method.includes('Ofen')">🍳 Ofen</span>
        <span v-if="recipe.method && recipe.method.includes('Heißluftfritteuse')">🍟 Heißluftfritteuse</span>
        <span v-if="recipe.method && recipe.method.includes('Herd')">🍳 Herd</span>
      </div>
      <div v-if="recipe.servings" class="servings">
        <p>Portionen: <strong>{{ recipe.servings }}</strong></p>
      </div>
      <p class="card-text">
        <small class="text-muted">von {{ username }}</small>
      </p>

      <!--<div v-if="recipe.mealType" class="meal-type">
        <p><strong>Gerichtstyp:</strong> {{ recipe.mealType }}</p>
      </div>-->

    </div>
    <h2>Zutaten</h2>
    <ul>
      <li v-for="(ingredient, index) in recipe.ingredients" :key="index">
        {{ ingredient.amount }} {{ ingredient.unit }} {{ ingredient.name }}
      </li>
    </ul>
    <h2>Anleitung</h2>
    <ol>
      <li v-for="(instruction, index) in recipe.instructions" :key="index">
        {{ instruction }}
      </li>
    </ol>
    <hr />
    <div class="review-section">
      
      <div v-if="!hasSubmittedReview">
        <h2>Bewertung abgeben</h2>
         <div v-if="!notLoggedIn">
          <div class="star-rating">
            <span v-for="star in 5" :key="star" class="star" @click="setRating(star)" :class="{ filled: star <= rating }">★</span>
          </div>
          <form @submit.prevent="submitReview">
            <textarea v-model="reviewText" class="form-control" placeholder="Schreibe deine Bewertung hier..."></textarea>
            <button type="submit" :disabled="isSubmitDisabled" class="btn btn-custom mt-2">Bewertung hochladen</button>
          </form>
        </div>
      </div>
      <p v-if="message">{{ message }}</p>
    </div>
    <div class="reviews">
      <h2>Bewertungen</h2>
      <div v-if="userReview" class="review user-review">
        <div v-if="isEditing">
          <div class="star-rating">
            <span v-for="star in 5" :key="star" class="star" @click="setRating(star)" :class="{ filled: star <= rating }">★</span>
          </div>
          <textarea v-model="reviewTextEdit" class="form-control" placeholder="Schreibe deine Bewertung hier..."></textarea>
          <div class="review-actions">
            <button class="btn btn-sm btn-action" @click="saveReview">
              <i class="bi bi-check"></i>
            </button>
            <button class="btn btn-sm btn-action" @click="cancelEdit">
              <i class="bi bi-x"></i>
            </button>
          </div>
        </div>
        <div v-else>
          <div class="star-rating">
            <span v-for="star in 5" :key="star" class="star" :class="{ filled: star <= userReview.rating }">★</span>
          </div>
          <p>{{ userReview.text }}</p>
          <div class="review-actions">
            <button class="btn btn-sm btn-action" @click="editReview(userReview)">
              <i class="bi bi-pencil-square"></i>
            </button>
            <button class="btn btn-sm btn-action" @click="deleteReview(userReview)">
              <i class="bi bi-trash2-fill"></i>
            </button>
          </div>
        </div>
      <p class="review-meta">
        <small>von {{ userReview.userName }} am {{ formatDate(userReview.date) }}</small>
      </p>
    </div>

    <div v-for="(review, index) in paginatedReviews.filter(review => review !== userReview)" :key="index" class="review">
        <div class="star-rating">
          <span v-for="star in 5" :key="star" class="star" :class="{ filled: star <= review.rating }">★</span>
        </div>
        <p>{{ review.text }}</p>
        <p class="review-meta">
          <small>von {{ review.userName }} am {{ formatDate(review.date) }}</small>
        </p>
      </div>
      <PaginationComponent
        :current-page="currentPage"
        :total-pages="totalPages"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getCookie } from '../cookieUtils';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import PaginationComponent from '@/components/PaginationComponent.vue';
import { jwtDecode } from 'jwt-decode';

let cache = {
  recipes: {},
  reviews: {}
};

SwiperCore.use([Autoplay, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    PaginationComponent
  },
  data() {
    return {
      recipe: {
        images: [],
        title: '',
        method: [],
        ingredients: [],
        instructions: [],
        username: '',
        hasSubmittedReview: false,
        message: '',
        notLoggedIn: false,
        servings: ''
      },
      reviews: [],
      newReview: {
        userid: '',
        rating: 0,
        text: '',
        date: new Date().toISOString()
      },
      imageSrcs: [],
      rating: 0,
      reviewText: '',
      reviewTextEdit: '',
      currentPage: 1,
      itemsPerPage: 3,

      editedReviewId: null,
      userReview: null,
      isEditing: false,
      userId: ''
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.rating === 0;
    },
    paginatedReviews() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.reviews.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.reviews.length / this.itemsPerPage);
    }
  },
  async created() {
    const recipeId = this.$route.params.id;
    try {
      // Check if the recipe is in the cache
      if (cache.recipes[recipeId]) {
        this.recipe = cache.recipes[recipeId];
      } else {
        // Fetch recipe data from the server
        const recipeResponse = await axios.get(`/api/recipe/${recipeId}`);
        this.recipe = recipeResponse.data;
        // Store the recipe in the cache
        cache.recipes[recipeId] = this.recipe;
      }

      this.username = this.recipe.username;

      // Check if reviews are in the cache
      if (cache.reviews[recipeId]) {
        this.reviews = cache.reviews[recipeId];
      } else {
        const reviewsResponse = await axios.get(`/api/reviews?id=${recipeId}`);
        this.reviews = reviewsResponse.data;
        // Store the reviews in the cache
        cache.reviews[recipeId] = this.reviews;
      }

      if (this.recipe.images && this.recipe.images.length > 0) {
        this.imageSrcs = await Promise.all(
          this.recipe.images.map(imageId => this.fetchImageFromKV(imageId))
        );
      }

      const token = getCookie('token');
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.username;

        this.userReview = this.reviews.find((review) => {
          return review.userId === userId;
        });

        if (this.userReview) {
          this.hasSubmittedReview = true;
        }
        //this.checkUserReview(recipeId, userId);
      } else {
        this.notLoggedIn = true;
        this.message = "Nur eingeloggte Benutzer können eine Bewertung abgeben";
        this.reviewText = 'a';
        this.reviewText = '';
        this.isSubmitDisabled = true;
      }
    } catch (error) {
      console.error('Error fetching recipe or reviews:', error);
    }

  },
  methods: {
    formatDate(dateString) {
      const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
      return new Date(dateString).toLocaleDateString('de-DE', options);
    },
    setRating(star) {
      this.rating = star;
      this.newReview.rating = star;
    },
    editReview(review) {
      this.isEditing = true;
      this.rating = review.rating;
      this.reviewTextEdit = review.text;
      this.editedReviewId = review._id;
    },
    handlePageChange(page) {
      this.currentPage = page;
    },

    cancelEdit() {
      this.isEditing = false;
    },
    async saveReview() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.username;
        const recipeId = this.$route.params.id;

        await axios.put(`/api/reviews/?id=${recipeId}`, {
          userId: userId,
          rating: this.rating,
          text: this.reviewTextEdit
        }, {
          headers: {
            Authorization: `Bearer ${getCookie('token')}`
          }
        });

        this.userReview.rating = this.rating;
        this.userReview.text = this.reviewTextEdit;
        this.isEditing = false;
      } catch (error) {
        console.error('Error saving review:', error);
      }
    },

    async deleteReview() {
      try {
        const recipeId = this.$route.params.id;
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.username;

        await axios.delete(`/api/reviews/?id=${recipeId}`, {
          data: {
            userId: userId
          },
          headers: {
            Authorization: `Bearer ${getCookie('token')}`,
            'Content-Type': 'application/json'
          },
        });

        this.reviews = this.reviews.filter((review) => review !== this.userReview);

        this.userReview = null;
        this.reviewText = '';
        this.rating = 0;
        this.hasSubmittedReview = false;

      } catch (error) {
        console.error('Error deleting review:', error);
      }
    },
    async submitReview() {
      const recipeId = this.$route.params.id;

      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.username;

      this.newReview.userId = userId;
      this.newReview.text = this.reviewText;
      this.newReview.date = new Date().toISOString();

      try {
        const response = await axios.post(`/api/reviews/?id=${recipeId}`, this.newReview, {
          headers: {
            Authorization: `Bearer ${getCookie('token')}`
          }
        });
        if (response.status === 201) {
          this.reviews.unshift(response.data); // Add new review to the beginning of the list
          this.newReview = { rating: 0, text: '', date: new Date().toISOString() }; // Reset form
          this.reviewText = '';
          this.rating = 0;
          // Recalculate evaluation
          this.calculateEvaluation();
          // Update cache
          cache.reviews[recipeId] = this.reviews;

          this.userReview = this.reviews.find((review) => review.userId === userId);
          this.hasSubmittedReview = true;
        } else if (response.status === 409) {
          this.hasSubmittedReview = true;

          //this.message = "Es wurde bereits eine Bewertung für dieses Rezept abgegeben.";
        } else if (response.status === 404) {
          this.message = "Nur eingeloggte Benutzer können eine Bewertung abgeben";
        }
      } catch (error) {
      console.error('Error submitting review:', error);
      this.message = "Es wurde bereits eine Bewertung für dieses Rezept abgegeben.";
    }
  },
  async fetchImageFromKV(kvUrl) {
    const kvImageId = kvUrl.split('kv://')[1]; // Extract the image ID from the URL
    const currentUrl = window.location.origin; // Get the current origin (protocol + hostname + port)
    const cacheKey = `${currentUrl}/api/kv?id=${kvImageId}`;

    // Open the cache
    const cache = await caches.open('recipe-images-cache');

    // Try to match the request in the cache
    const cachedResponse = await cache.match(cacheKey);
    if (cachedResponse) {
      // If the response is found in the cache, return the cached blob URL
      const blob = await cachedResponse.blob();
      return URL.createObjectURL(blob);
    }

    // If the response is not found in the cache, fetch it from the network
    const response = await fetch(cacheKey);
    if (!response.ok) {
      throw new Error(`Failed to fetch image: ${response.statusText}`);
    }

    // Clone the response and store it in the cache
    const responseClone = response.clone();
    cache.put(cacheKey, responseClone);

    // Return the blob URL
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  },
    async checkUserReview(recipeId, userId) {
      try {
        const existingReview = await axios.get(`/api/reviews/check?recipeId=${recipeId}&userId=${userId}`);
        if (existingReview.data.exists) {
          this.hasSubmittedReview = true;
          this.message = "Es wurde bereits eine Bewertung für dieses Rezept abgegeben."
        }
      } catch (error) {
        console.error('Error checking user review:', error);
      }
    },
    calculateEvaluation() {
        if (this.reviews.length > 0) {
            const total = this.reviews.reduce((acc, review) => acc + review.rating, 0);
            this.recipe.evaluation = total / this.reviews.length;
        } else {
            this.recipe.evaluation = 0;
        }
    }
  }
};
</script>


<style scoped>
.carousel {
  width: 100%;
  max-width: 800px;
  margin: 0 auto 20px;
}

.carousel-img {
  width: 100%;
  height: 300px; /* Adjusted height */
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
  display: block;
  border-radius: 10px;
}

.recipe-detail {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.method {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

ul, ol {
  margin: 20px 0;
  padding: 0 20px;
}

.review-section {
  margin-top: 30px;
}

.star-rating .star {
  color: #ccc;
  font-size: 20px;
  cursor: pointer;
}

.star-rating .star.filled {
  color: #ac8d7e; /* Same color as the button */
}

.btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

h2 {
  margin-top: 30px;
}

.review-meta {
  color: #888;
  font-size: 0.9em;
  margin-top: 5px;
}

.review-actions {
  display: inline-flex;
  gap: 8px;
  margin-top: 5px;
}

.btn-action {
  background-color: #ac8d7e; /* Your primary button color */
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 0;
}

.btn-action:hover {
  background-color: #ac8d7e; /* A darker shade of your primary color */
}
</style>