<template>
    <div class="tag-selector">
      <button
        v-for="tag in tags"
        :key="tag"
        :class="{'selected': selectedTags.includes(tag)}"
        @click="toggleTag(tag,$event)"
        class="tag-button"
      >
        {{ tag }}
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      tags: {
        type: Array,
        required: true
      },
      modelValue: {
        type: Array,
        required: true
      }
    },
    computed: {
      selectedTags() {
        return this.modelValue;
      }
    },
    methods: {
      toggleTag(tag, event) {
        event.preventDefault();
        event.stopPropagation();
        
        let newTags = [...this.selectedTags];
        const index = newTags.indexOf(tag);
        if (index > -1) {
          newTags.splice(index, 1);
        } else {
          newTags.push(tag);
        }
        this.$emit('update:modelValue', newTags);
      }
    }
  }
  </script>
  
  <style scoped>
  .tag-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
  }
  .tag-button {
    background: none;
    border: 1px solid #ac8d7e;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    display: inline-block;
  }
  .tag-button.selected {
    background-color: #ac8d7e;
    color: white;
  }
  </style>