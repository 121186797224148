<template>
  <div class="container mt-5">
 <h1>Datenschutzerklärung</h1>
 <br>
    <p>Hier möchten wir über die Art und Weise wie Daten auf unserer Webseite (meisterchef.de) verarbeitet werden informieren.</p><br>
    
    <strong>Verantwortlicher für die Datenverarbeitung</strong> <br><br>
    
    <p>Verantwortlicher gemäß Art. 4 Abs. 7 DSGVO ist:<br><br>
    Trederklein GbR<br>
      Seestr. 2<br>
      30916 Isernhagen<br>
      E-Mail: trederklein@gmail.com<br>
    </p><br>

    <strong>Informationen über die Verwendung Ihrer Daten auf meisterchef.de</strong> <br><br>

    <h3>Verwendung von Cookies auf meisterchef.de</h3><br>

    <p>Auf meisterchef.de werden zwei Cookies gesetzt:</p><br>
    <p><strong>Token:</strong> Dieser Cookie namens "token" wird gesetzt, sobald sich ein Nutzer einloggt. Der Token ist nutzerspezifisch und identifiziert den Nutzer eindeutig.</p><br>
    <p><strong>Cookieconsent:</strong> Dieser Cookie namens "cookieconsent" wird gesetzt, sobald der Nutzer in die Nutzung von Cookies einwilligt. Er enthält die Einwilligungen des Nutzers, z.B. ob funktionale, statistische oder Marketing-Cookies gesetzt werden dürfen.</p><br>

    <h3>Personenbezogene Daten</h3><br>
    <p>Auf meisterchef.de werden folgende personenbezogene Daten erhoben:</p><br>
    <p><strong>Benutzername und Passwort:</strong> Diese Daten werden bei der Registrierung und Anmeldung zur Identifizierung und Authentifizierung des Nutzers erhoben.</p><br>
    <p><strong>Verifizierungsdaten:</strong> Um die Sicherheit zu gewährleisten, wird Google Authenticator für die Zwei-Faktor-Authentifizierung (2FA) genutzt. Hierbei wird ein einmaliger Verifizierungscode generiert, um die Identität des Nutzers zu bestätigen.</p><br>
    <p><strong>JWT-Token:</strong> Bei der Anmeldung wird ein JWT-Token generiert, um die Sitzung des Nutzers zu verwalten und den Zugang zu bestimmten Bereichen der Website zu steuern.</p><br>
    <p>Diese Daten werden ausschließlich zur Verwaltung von Benutzerkonten und zur Gewährleistung der Sicherheit auf unserer Website verwendet.</p><br>
    
    <strong>Änderung der Datenschutzerklärung</strong><br><br>
    <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit unter Beachtung der geltenden Datenschutzvorschriften zu ändern. Derzeitiger Stand ist Juli 2024.</p>
  </div>
</template>

<script>
export default {
  name: 'DatenschutzPage'
}
</script>