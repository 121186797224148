<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="login" class="form">
      <div class="form-group">
        <label for="username">Username</label>
        <input type="text" id="username" v-model="username" required class="form-control" />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="password" required class="form-control" />
      </div>
      <button type="submit" class="btn btn-login">Login</button>
    </form>
    <p v-if="message">{{ message }}</p>
    <router-link to="/register" class="register-link">Noch keinen Account? Hier registrieren</router-link>
    <router-link to="/forgot-password" class="register-link">Passwort vergessen?</router-link>
    
    <ConsentModal
      :showModal="showConsentModal"
      @accept-consent="handleAcceptConsent"
      @cancel="handleCancelConsent"
    />
 </div>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuth } from '../store/auth';
import ConsentModal from '../components/ConsentModal.vue'; // Import the ConsentModal component

export default {
  name: 'UserLogin',
  components: {
    ConsentModal
  },
  data() {
    return {
      user: {
        username: '',
        password: ''
      }
    };
  },
  setup() {
    const username = ref('');
    const password = ref('');
    const message = ref('');
    const router = useRouter();
    const { login, checkConsent, acceptConsent } = useAuth();
    const showConsentModal = ref(false);

    const handleLogin = async () => {
      try {
        const consent = await checkConsent(username.value);
        if (!consent) {
          showConsentModal.value = true; 
          return;
        }
        await login(username.value, password.value);
        router.push('/');
        //this.router.go(0)
      } catch (error) {
        console.error('Login failed:', error);
        message.value = 'Falscher Nutzername oder Passwort.';
      }
    };

  const handleAcceptConsent = async () => {
    try {
      await acceptConsent(username.value);
      await handleLogin(); // Try logging in again after accepting consent
    } catch (error) {
      console.error('Consent acceptance failed:', error);
    } finally {
      showConsentModal.value = false;
    }
  };

  const handleCancelConsent = () => {
    showConsentModal.value = false;
  };

  return { username, password, message, login: handleLogin, handleCancelConsent, handleAcceptConsent, showConsentModal };
  }
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #495057;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.btn-login {
  width: 100%;
  padding: 10px;
  background-color: #ac8d7e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-login:hover {
  background-color: #c08c5a;
}

.btn-forgot-password {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #6c757d;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-forgot-password:hover {
  background-color: #5a6268;
}

.register-link {
  display: block;
  margin-top: 20px;
  text-align: center;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.register-link:hover {
  color: #0056b3;
}
</style>