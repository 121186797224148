<template>
    <div class="method-selector">
      <button
        v-for="method in methods"
        :key="method"
        :class="{'selected': selectedMethods.includes(method)}"
        @click="toggleMethod(method, $event)"
        class="method-button"
      >
        <img :src="getImage(method)" :alt="method" class="method-image"/>
        <span>{{ method }}</span>
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      methods: {
        type: Array,
        required: true
      },
      modelValue: {
        type: Array,
        required: true
      }
    },
    computed: {
      selectedMethods() {
        return this.modelValue;
      }
    },
    methods: {
      toggleMethod(method, event) {
        event.preventDefault();
        event.stopPropagation();
        let newMethods = [...this.selectedMethods];
        const index = newMethods.indexOf(method);
        if (index > -1) {
          newMethods.splice(index, 1);
        } else {
          newMethods.push(method);
        }
        this.$emit('update:modelValue', newMethods);
      },
      getImage(method) {
        const images = {
          'Stove': 'path/to/stove-image.png',
          'Air Fryer': 'path/to/airfryer-image.png',
          'Oven': 'path/to/oven-image.png'
        };
        return images[method] || 'path/to/default-image.png';
      }
    }
  }
  </script>
  
  <style scoped>
  .method-selector {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .method-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  .method-button.selected {
    border: 2px solid #ac8d7e;
  }
  .method-image {
    width: 50px;
    height: 50px;
  }
  .method-button span {
    margin-top: 5px;
    font-size: 1rem;
    color: #ac8d7e;
  }
  </style>