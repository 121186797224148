<template>
  <div class="list-item-container">
    <div class="list-item">
      <input
        type="number"
        v-model.number="editableQuantity"
        @blur="updateQuantity"
        min="0"
        class="quantity-input no-background"
      />
      <select
        v-model="editableUnit"
        @change="updateUnit"
        class="unit-dropdown no-background"
      >
        <option value="g">g</option>
        <option value="kg">kg</option>
        <option value="ml">ml</option>
        <option value="EL">EL</option>
        <option value="TL">TL</option>
        <option value="Pck">Pck</option>
        <option value="Stck">Stck</option>
      </select>
      <input 
        type="text" 
        v-model="editableTitle" 
        @blur="updateTitle" 
        class="title-input no-background"
      />
      <div class="item-actions">
        <i 
          class="bi bi-info-square-fill" 
          :class="{ 'active-info': showInfo }" 
          @click="toggleInfo"
        ></i>
        <i class="bi bi-trash2-fill" @click="deleteItem"></i>
      </div>
    </div>

    <!-- Tooltip that shows Ablaufdatum and Tags -->
    <div v-if="showInfo" class="tooltip-bubble">
      <p v-if="item.expiration && item.expiration !== 'N/A'">Ablaufdatum: {{ item.expiration }}</p>
      <p v-if="item.tags && item.tags.length > 0">Tags: {{ item.tags.join(', ') }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      editableQuantity: this.item.quantity, // Editable quantity field
      editableUnit: this.item.quantityUnit, // Editable unit field
      editableTitle: this.item.title,
      showInfo: false, // Control the visibility of the tooltip
    };
  },
  watch: {
    item: {
      handler(newItem) {
        this.editableQuantity = newItem.quantity;
        this.editableUnit = newItem.quantityUnit;
        this.editableTitle = newItem.title;
      },
      deep: true,
    },
  },
  methods: {
    toggleInfo() {
      this.showInfo = !this.showInfo; // Toggle the info display
    },
    async updateQuantity() {
    try {
      if (this.editableQuantity > 0) {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const oldTitle = this.item.title; 

        const itemData = { ...this.item, quantity: this.editableQuantity };
        await axios.put(`/api/storageItem/?userid=${username}&title=${this.item.title}`, itemData);

        // Emit the updated item to the parent component
        this.$emit('itemUpdated', { oldTitle, updatedItem: itemData });
      }
    } catch (error) {
      console.error('Error updating quantity:', error);
    }
  },
  async updateUnit() {
    try {
      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const oldTitle = this.item.title; 

      const itemData = { ...this.item, quantityUnit: this.editableUnit };
      await axios.put(`/api/storageItem/?userid=${username}&title=${this.item.title}`, itemData);

      // Emit the updated item to the parent component
      this.$emit('itemUpdated', { oldTitle, updatedItem: itemData });
    } catch (error) {
      console.error('Error updating unit:', error);
    }
  },
  async updateTitle() {
    try {
      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      const oldTitle = this.item.title; 
      const itemData = { ...this.item, title: this.editableTitle };

      await axios.put(`/api/storageItem/?userid=${username}&title=${this.item.title}`, itemData);

      // Emit the updated item to the parent component
      this.$emit('itemUpdated', { oldTitle, updatedItem: itemData });
    } catch (error) {
      console.error('Error updating title:', error);
    }
  },
    async deleteItem() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        await axios.delete(`/api/storageItem/?userid=${username}&title=${this.item.title}`);
        this.$emit('itemDeleted', this.item.title); // Emit event to parent to handle UI update
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },
  },
};
</script>

<style scoped>
.list-item-container {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0 !important;
  padding: 0;
  flex-wrap: nowrap;
}

.quantity-input {
  width: 60px; /* Adjust width for responsive design */
  text-align: left;
  border: 1px solid #ddd;
  background: none;
  padding: 5px;
  margin-right: 5px;
  flex-shrink: 0; /* Prevent shrinking of input field */
}

.unit-dropdown {
  border: 1px solid #ddd;
  background: none;
  margin-right: 10px;
  flex-shrink: 0; /* Prevent shrinking of dropdown */
}

.title-input {
  flex-grow: 1; /* Allow title to take the remaining space */
  text-align: left;
  white-space: normal; /* Allow text to wrap to the next line */
  overflow-wrap: break-word; /* Break words if they are too long */
  margin-right: 10px;
  padding: 5px;
  min-width: 120px; /* Ensure minimum width for the title input */
}

.item-actions {
  display: flex;
  align-items: center; /* Center icons vertically with the text */
  margin-left: auto; /* Align actions to the right */
  flex-shrink: 0; /* Prevent shrinking of actions */
}

.item-actions i {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
}

.item-actions i:hover,
.active-info {
  color: #ac8d7e; /* Brown color for hover and active state */
}

.tooltip-bubble {
  position: absolute;
  right: 40px;
  top: 40px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  width: max-content;
}

.tooltip-bubble p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.no-background {
  background-color: transparent;
  color: #000;
  appearance: none;
  border: none;
}
</style>