// src/cookieUtils.js
export function setCookie(name, value, days) {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/; Secure`;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    const result = parts.pop().split(';').shift();
    return result;
  }
  return null;
}

export function deleteCookie(name) {
  document.cookie = `${name}=; Max-Age=-99999999; path=/; Secure;`;
}