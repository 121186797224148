<template>
  <div class="register-container">
    <h2>Register</h2>
    <form @submit.prevent="requestVerificationCode" class="form">
      <div v-if="!verificationRequested">
        <div class="form-group">
          <label for="username">Benutzername</label>
          <input
            type="text"
            id="username"
            v-model="username"
            @input="checkUsername"
            required
            class="form-control"
          />
          <div v-if="usernameExists === true" class="text-danger">
            <i class="bi bi-x-circle-fill"></i> Dieser Benutzername existiert bereits
          </div>
          <div v-if="usernameExists === false" class="text-success">
            <i class="bi bi-check-circle-fill"></i> Benutzername verfügbar
          </div>
        </div>
        <div class="form-group">
          <label for="password">Passwort</label>
          <input
            type="password"
            id="password"
            v-model="password"
            required
            class="form-control"
          />
          <div v-if="password.length > 0 && !isPasswordValid" class="text-danger validation-message">
            <i class="bi bi-x-circle-fill"></i> Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthalten.
          </div>
          <div v-if="isPasswordValid && password.length > 0" class="text-success validation-message">
            <i class="bi bi-check-circle-fill"></i> Passwort erfüllt die Anforderungen
          </div>
        </div>
        <button type="submit" class="btn btn-register" :disabled="!isPasswordValid">Weiter</button>
      </div>
      <div v-if="verificationRequested" class="form-group">
        <div v-html="qrCode" class="qr-code"></div>
        <p>Alternativer code: <strong>{{ secret }}</strong></p>
        <label for="verificationCode">Authenticator Code</label>
        <input
          v-model="verificationCode"
          type="text"
          id="verificationCode"
          class="form-control"
          placeholder="Authenticator Code"
          required
        />

        <div class="form-group">
          <privacy-checkbox v-model:checked="privacyPolicyAccepted" />
         </div>

         <button @click="handleRegister" :disabled="!canRegister" class="btn btn-register">Registrieren</button>
      </div>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import PrivacyCheckbox from '../components/PrivacyCheckbox.vue';
import { useAuth } from '../store/auth';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
  name: 'UserRegister',
  components: {
    PrivacyCheckbox,
  },
  setup() {
    const username = ref('');
    const password = ref('');
    const verificationCode = ref('');
    const verificationRequested = ref(false);
    const message = ref('');
    const qrCode = ref('');
    const secret = ref('');
    const usernameExists = ref(null);
    const router = useRouter();
    const { register } = useAuth();
    const privacyPolicyAccepted = ref(false);

    const checkUsername = async () => {
      if (username.value.trim() === '') {
        usernameExists.value = null;
        return;
      }
      try {
        const response = await axios.get(`/api/checkUsername?username=${username.value}`);
        usernameExists.value = response.data.exists;
      } catch (error) {
        console.error('Error checking username:', error);
        usernameExists.value = null;
      }
    };

    const canRegister = computed(() => {
      return privacyPolicyAccepted.value && verificationCode.value.length > 0;
    });

    const validatePassword = (password) => {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;
      return passwordRegex.test(password);
    };

    const isPasswordValid = computed(() => validatePassword(password.value));

    watch(password, (newValue) => {
      if (newValue.length > 0 && !isPasswordValid.value) {
       // message.value = 'Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthalten.';
      } else {
        message.value = '';
      }
    });

    const requestVerificationCode = async () => {
      if (usernameExists.value) {
        message.value = 'Dieser Benutzername existiert bereits.';
        return;
      }
      try {
        const qrResponse = await axios.post('/api/generateQRCode', { username: username.value });
        verificationRequested.value = true;
        qrCode.value = qrResponse.data.qrCode;
        secret.value = qrResponse.data.secret;

        message.value = 'Scanne den QR Code mit einer Authenticator App';
      } catch (error) {
        console.error('Error generating QR code:', error);
        message.value = 'Failed to generate QR code.';
      }
    };

    const handleRegister = async () => {
      if (!isPasswordValid.value) {
        message.value = 'Passwort erfüllt die Anforderungen nicht.';
        return;
      }
      try {
        await register(username.value, password.value, secret.value, verificationCode.value);
        await saveConsent();
        router.push('/');
        
      } catch (error) {
        console.error('Register failed:', error);
        message.value = 'Fehler bei der Registrierung.';
      }
    };

    const saveConsent = async () => {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const consentData = {
          agb: true,
          datenschutzerklaerung: true,
        };

        const response = await axios.post(`/api/consent/?id=${username}`, consentData);
        if (response.status === 200) {
          console.log('Consent saved successfully');
        }
      } catch (error) {
        console.error('Error saving consent:', error);
      }
    };

    return {
      username,
      password,
      verificationCode,
      verificationRequested,
      message,
      requestVerificationCode,
      handleRegister,
      qrCode,
      secret,
      usernameExists,
      checkUsername,
      isPasswordValid,
      saveConsent,
      privacyPolicyAccepted,
      canRegister
    };
  }}
</script>

<style scoped>
.register-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  margin-bottom: 20px;
  color: #343a40;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #495057;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.text-danger {
  color: red; /* Explicitly set the error text color to red */
}

.text-success {
  color: green; /* Optional: Explicitly set the success text color to green */
}

.validation-message {
  margin-top: 5px;
  font-size: 14px;
}

.btn-register {
  width: 100%;
  padding: 10px;
  background-color: #ac8d7e;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-register:hover {
  background-color: #c08c5a;
}

.btn-register:disabled {
  background-color: #e0a899;
  cursor: not-allowed;
}

.qr-code {
  display: block;
  margin: 20px auto;
}
</style>