<template>
  <div class="pagination">
    <button @click="prevPage" :disabled="currentPage === 1">Zurück</button>
    <button
      v-for="page in totalPagesArray"
      :key="page"
      :class="{ active: page === currentPage }"
      @click="changePage(page)"
    >
      {{ page }}
    </button>
    <button @click="nextPage" :disabled="currentPage === totalPages">Vor</button>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
    totalPagesArray() {
      return Array.from({ length: this.totalPages }, (v, k) => k + 1);
    }
  },
  methods: {
    changePage(page) {

      if (page !== this.currentPage) {
        this.$emit('page-changed', page);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.changePage(this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.changePage(this.currentPage + 1);
      }
    }
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ac8d7e; /* Border color similar to the search input */
  background-color: transparent; /* Transparent background */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  color: black; /* Text color */
  font-size: 16px; /* Adjust font size for better visibility */
}

button:hover {
  background-color: #ac8d7e; /* Brown background on hover */
  color: white; /* White text on hover */
  border-color: #ac8d7e; /* Border matches background on hover */
}

button:disabled {
  background-color: transparent; /* Keep background transparent */
  cursor: not-allowed;
  color: #666; /* Gray color for disabled text */
  border-color: #ccc; /* Light gray border for disabled state */
}

button.active {
  background-color: #ac8d7e; /* Active state background */
  color: white; /* Active state text */
  border-color: #ac8d7e; /* Active state border */
}

button {
  -webkit-appearance: none; /* Remove default styling for Safari */
  appearance: none; /* Remove default styling for other browsers */
  outline: none; /* Remove default outline */
}

button:focus {
  outline: none; /* Remove focus outline on mobile */
}
</style>