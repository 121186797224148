<template>
  <div>
    <DishesModal 
      :showModal="showStorageModal"
      :mismatchedIngredients="mismatchedIngredients"
      @close-modal="showStorageModal = false"
      @confirm-changes="confirmChanges"
    />
    <div class="dishes-container row" ref="dishesContainer">
      <div v-for="(dish) in localDishItems" :key="dish.id" class="dish-item">
        <div class="d-flex justify-content-between align-items-start">
          <div>
            <h5 class="card-title">{{ dish.title }}</h5>
            
          </div>
          <div class="actions d-flex align-items-start">
            <button class="btn delete-button" @click="deleteItem(dish.title)">
              <i class="bi bi-trash2-fill"></i>
            </button>
            <button class="btn action-button" @click="addToList(dish)">
              <i class="bi bi-box-seam-fill"></i>
            </button>
            <button class="btn action-button" @click="confirmAndDecreaseQuantity(dish)">
              <i class="bi bi-check-square-fill"></i>
            </button>
          </div>
        </div>
        <small class="ingredients-list">
          Zutaten:
          <template v-for="(ingredient, index) in dish.ingredients" :key="ingredient.name">
            <span
              :class="{
                'bg-light-green': isInStorage(ingredient) === true,
                'bg-light-orange': isInStorage(ingredient) === 'light-green-yellow',
                'bg-light-yellow': isInStorage(ingredient) === false,
                'ingredient': true 
              }"
            >
              {{ ingredient.amount }} {{ ingredient.unit }} {{ ingredient.name }}
            </span>
            <span v-if="index < dish.ingredients.length - 1">, </span>
          </template>
        </small>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';
import Sortable from 'sortablejs';
import DishesModal from './DishesModal.vue';

export default {
  components: {
    DishesModal,
  },
  props: {
    dishesItems: {
      type: Array,
      required: true,
    },
    storageItems: {
      type: Array,
      required: true, // Make it required if necessary
    }
  },
  data() {
    return {
      localDishItems: [...this.dishesItems], // Create a local copy of the dishItems prop
      localStorageItems: [...this.storageItems], // Create a local copy of the storageItems prop
      showStorageModal: false,
      mismatchedIngredients: [],
    };
  },
  watch: {
    dishesItems: {
      handler(newItems) {
        this.localDishItems = [...newItems]; // Update local copy if parent prop changes
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.localStorageItems || this.localStorageItems.length === 0) {
      this.fetchStorageItems();
    }
    this.initSortable();
  },
  methods: {
    async addToList(dish) {
    try {
      const token = getCookie('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const decodedToken = jwtDecode(token);
      const username = decodedToken.username;

      for (const ingredient of dish.ingredients) {
        // Split the amount into quantity and unit
        const [quantity, ...unitParts] = ingredient.amount.split(' ');
        const unit = unitParts.join(' ');  // Join back in case the unit contains spaces

        const titleToCheck = ingredient.name.toLowerCase().trim();
        const response = await axios.get(`/api/checkListTitle?userid=${username}&title=${encodeURIComponent(titleToCheck)}`);

        let shouldAddToList = false;
        let quantityToAdd = parseFloat(quantity);

        if (response.data.exists) {
          // If the item exists in the list, get the existing item details
          const existingListItem = (await axios.get(`/api/listItem/?id=${username}`)).data.find(item => item.title.toLowerCase() === titleToCheck);

          if (existingListItem.quantityUnit === unit) {
            // Check the storage to see if the item is fully available
            //const storageItem = this.storageItems.find(storageItem => storageItem.title.toLowerCase() === titleToCheck);
            const storageItem = this.localStorageItems.find(storageItem => storageItem.title.toLowerCase() === titleToCheck);
  
            if (storageItem) {
              if (storageItem.quantity < parseFloat(quantity)) {
                // If the storage quantity is less, calculate the missing quantity
                quantityToAdd = parseFloat(quantity) - storageItem.quantity;
                shouldAddToList = true;
              }
            } else {
              // If not in storage, add the full quantity
              shouldAddToList = true;
            }
          } else {
            this.mismatchedIngredients.push({ ingredient, listItem: existingListItem });
          }

          if (shouldAddToList) {
            await axios.put(`/api/listItem/?userid=${username}&title=${existingListItem.title}`, {
              quantity: existingListItem.quantity + quantityToAdd,
              quantityUnit: existingListItem.quantityUnit,
            });
          }
        } else {
          // If the item doesn't exist in the list, check if it's fully available in storage
          //const storageItem = this.storageItems.find(storageItem => storageItem.title.toLowerCase() === titleToCheck);
          const storageItem = this.localStorageItems.find(storageItem => storageItem.title.toLowerCase() === titleToCheck);
  
          if (storageItem) {
            if (storageItem.quantity < parseFloat(quantity)) {
              // If not fully available, add the missing quantity to the list
              quantityToAdd = parseFloat(quantity) - storageItem.quantity;
              await axios.post(`/api/listItem/?id=${username}`, {
                title: ingredient.name,
                quantity: quantityToAdd,
                quantityUnit: unit,
              });
            }
          } else {
            // If not in storage, add the full quantity
            await axios.post(`/api/listItem/?id=${username}`, {
              title: ingredient.name,
              quantity: parseFloat(quantity),
              quantityUnit: unit,
            });
          }
        }
      }

      if (this.mismatchedIngredients.length > 0) {
        this.showStorageModal = true;
      }

    } catch (error) {
      console.error('Error adding ingredients to list:', error);
    }
  },

    async confirmAndDecreaseQuantity(dish) {
      await this.decreaseQuantity(dish);
      await this.updateStorage(dish);
    },
    async fetchStorageItems() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`/api/storageItem/?id=${username}`);
        this.localStorageItems = response.data;
      } catch (error) {
        console.error('Error fetching storage items:', error);
      }
    },
    initSortable() {
      new Sortable(this.$refs.dishesContainer, {
        handle: '.reorder-button',
        animation: 150,
        onEnd: this.onEnd,
      });
    },
   
    isInStorage(ingredient) {
  const ingredientName = ingredient.name.toLowerCase().trim();

  let ingredientAmount = '';
  let ingredientUnit = '';

  if (ingredient.amount && typeof ingredient.amount === 'string') {
    const splitAmount = ingredient.amount.split(/\s+/);
    ingredientAmount = parseFloat(splitAmount[0]);

    if (isNaN(ingredientAmount)) {
      ingredientAmount = 0;
    }

    ingredientUnit = splitAmount[1] ? splitAmount[1].toLowerCase() : '';
  }

  let hasEnoughInStorage = null;

  const normalizedUnits = {
    kg: 1000,
    g: 1,
    l: 1000,
    ml: 1,
  };

  this.localStorageItems.some((item) => {// Use the prop storageItems
    if (!item || !item.title) return false;

    const normalizedTitle = item.title.toLowerCase().trim();
    const itemWords = normalizedTitle.split(/\s+/); // Split title into individual words
    const ingredientWords = ingredientName.split(/\s+/); // Split ingredient name into individual words

    const itemUnit = item.quantityUnit ? item.quantityUnit.toLowerCase() : '';
    let itemAmount = item.quantity ? item.quantity : 0;

    if (itemAmount === 0) {
      hasEnoughInStorage = null; // No color
      return false; // Exit early, no need to check further
    }

    // Check if all words in ingredient match exactly or if a meaningful word is found in storage item
    const fullMatch = ingredientWords.every(ingredientWord =>
      itemWords.includes(ingredientWord)
    ) || ingredientWords.some(ingredientWord =>
      itemWords.includes(ingredientWord)
    );

    if (fullMatch) {
      if (ingredientUnit && itemUnit && normalizedUnits[ingredientUnit] && normalizedUnits[itemUnit]) {
        itemAmount *= normalizedUnits[itemUnit];
        ingredientAmount *= normalizedUnits[ingredientUnit];
      }

      if (!ingredientUnit || !itemUnit || itemUnit === ingredientUnit) {
        if (itemAmount >= ingredientAmount && ingredientAmount !== undefined) {
          hasEnoughInStorage = true; // Green color
        } else if (itemAmount < ingredientAmount) {
          hasEnoughInStorage = 'light-green-yellow'; 
        }
      } else if (ingredientUnit !== itemUnit) {
        hasEnoughInStorage = false; 
      }

      return true; // Stop the search
    }
    
    return false;
  });

  return hasEnoughInStorage;
},
    async updateStorage(dish) {
      const mismatchedIngredients = [];

      for (const ingredient of dish.ingredients) {
        if (!ingredient || !ingredient.name) {
          continue; // Skip if ingredient is undefined or doesn't have a name
        }

        const ingredientName = ingredient.name.toLowerCase().trim();
        let ingredientAmount = '';
        let ingredientUnit = '';

        if (ingredient.amount) {
          const splitAmount = ingredient.amount.split(/\s+/);
          ingredientAmount = parseFloat(splitAmount[0]);

          if (isNaN(ingredientAmount)) {
            ingredientAmount = 0;
          }

          ingredientUnit = splitAmount[1] ? splitAmount[1].toLowerCase() : '';
        }

        const normalizedUnits = {
          kg: 1000,
          g: 1,
          l: 1000,
          ml: 1,
        };

        const storageItem = this.localStorageItems.find((item) => {
          const normalizedTitle = item.title.toLowerCase().trim();
          const titleWords = normalizedTitle.split(/\s+/);
          const ingredientWords = ingredientName.split(/\s+/);

          const itemUnit = item.quantityUnit ? item.quantityUnit.toLowerCase() : '';
          let itemAmount = item.quantity ? item.quantity : 0;

          if(item.quantity == 0)
          {
            return
          }

          // Check for full matches or for the presence of any meaningful word
          const fullMatch = ingredientWords.some(ingredientWord =>
            titleWords.includes(ingredientWord)
          );

          if (fullMatch) {
            if (ingredientUnit && itemUnit && normalizedUnits[ingredientUnit] && normalizedUnits[itemUnit]) {
              itemAmount *= normalizedUnits[itemUnit];
              ingredientAmount *= normalizedUnits[ingredientUnit];
            }

            if (itemUnit === ingredientUnit || !ingredientUnit) {
              // If the storage quantity is enough, decrease the quantity
              if (itemAmount >= ingredientAmount && ingredientAmount !== undefined) {
                item.quantity = Math.max(0, itemAmount - ingredientAmount);
                this.updateStorageItem(item.title, item.quantity, item.quantityUnit);
                return false; // This means it matched and handled properly
              } else if (itemAmount < ingredientAmount) {
                return true; // This means it is mismatched due to insufficient quantity
              }
            } else if (ingredientUnit !== itemUnit) {
              return true; // This means it is mismatched due to different units
            }
          }

          return false; // This means no match
        });

        if (storageItem) {
          if (storageItem.quantity === 0) {
            return false; // Skip this ingredient if storage amount is 0
          }

          // Add to mismatched ingredients
          mismatchedIngredients.push({ 
            ingredient: {
              name: storageItem.title,
              quantity: storageItem.quantity, 
              quantityUnit: storageItem.quantityUnit 
            }, 
            storageItem 
          });
        }
      }

      // If there are any mismatched ingredients, show the modal
      if (mismatchedIngredients.length > 0) {
        this.mismatchedIngredients = mismatchedIngredients;
        this.showStorageModal = true;
      }

      return mismatchedIngredients; // Return the mismatchedIngredients array
    },
    async updateStorageItem(title, newAmount, unit) {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const updatedItem = {
          quantity: newAmount,
          unit: unit,
        };

        await axios.put(`/api/storageItem/?userid=${username}&title=${title}`, updatedItem);

       
        const itemIndex = this.localStorageItems.findIndex(item => item.title === title);
        if (itemIndex !== -1) {
          this.localStorageItems[itemIndex].quantity = newAmount;
        }
      } catch (error) {
        console.error('Error updating storage item:', error);
      }
    },
    async increaseQuantity(dish) {
      dish.amount += 1;
      this.updateQuantity(dish);
    },
    async decreaseQuantity(dish) {
      dish.amount -= 1;
      
      if (dish.amount >= 0) {
        // First, update the dish
        await this.updateQuantity(dish);

        // Now, update the storage
        await this.updateStorage(dish);  // No need for additional checks here, as updateStorage handles everything
      }
    },
    async confirmChanges(updatedIngredients) {

      for (const item of updatedIngredients) {
        const { name, quantity: userQuantity } = item.ingredient;

        // Find the matching storage item
        const storageItem = this.localStorageItems.find(storageItem => storageItem.title === name);

        if (storageItem) {
          // Calculate the new quantity in storage
          const newQuantity = Math.max(0, storageItem.quantity - userQuantity);

          // Update the storage item with the new quantity
          storageItem.amount = newQuantity;

          // Update the storage item in the KV storage
          await this.updateStorageItem(storageItem.title, storageItem.amount, storageItem.quantityUnit);
        }
      }
      this.showStorageModal = false; // Close the modal after saving changes
    },
    async updateQuantity(dish) {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.put(`/api/dishesItem/?userid=${username}&title=${dish.title}`, dish);
        if (response.status !== 200) {
          throw new Error(`Failed to update dish: ${response.statusText}`);
        }
        this.$emit('update:dishesItems', this.localDishItems);
      } catch (error) {
        console.error('Error updating dish:', error);
      }
    },
    async deleteItem(dishTitle) {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.delete(`/api/dishesItem/?userid=${username}&title=${dishTitle}`);
        if (response.status !== 200) {
          throw new Error(`Failed to delete dish: ${response.statusText}`);
        }
        this.localDishItems = this.localDishItems.filter(dish => dish.title !== dishTitle);
        this.$emit('update:dishesItems', this.localDishItems);
        //this.$emit('itemDeleted', dishTitle);
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    },
    async onEnd() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.put(`/api/dishesInteract/?userid=${username}`, this.localDishItems);
        if (response.status !== 200) {
          throw new Error(`Failed to reorder dishes: ${response.statusText}`);
        }
        this.$emit('itemUpdated', this.localDishItems);
      } catch (error) {
        console.error('Error reordering dishes:', error);
      }
    },
  }
};
</script>

<style scoped>
.dishes-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dish-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.card-title {
  margin: 0 0 5px 0;
  font-size: 1.25rem;
}

.ingredients-list {
  display: block;
}

.actions {
  gap: 5px;
}

.actions .btn {
  padding: 0 8px;
  font-size: 18px;
  border-radius: 4px;
  border: none;
  background: none;
}

.actions .btn:hover {
  color: #ac8d7e;
}

.delete-button {
  font-size: 18px;
}

.bg-light-green {
  background-color: lightgreen;
}

.bg-light-orange {
  background-color: #FFD580;
}

.bg-light-yellow {
  background-color: #d4ff80;
}

.ingredient {
  margin-right: 2px;
  border-radius: 3px;
}
</style>