<template>
    <div class="d-flex justify-content-center align-items-center">
      <div class="list-item-container">
        <div class="list-item">
          <span class="title-text" >{{ item.quantity }} {{ item.quantityUnit }} {{ item.title }}</span>
          <div class="item-actions">
            <i class="bi bi-box-seam-fill" @click="moveToStorage"></i>
            <i class="bi bi-check-square-fill" @click="deleteItem"></i>
          </div>
        </div>
      </div>
      <!-- Modal Component -->
      <ListModal
        v-if="showModal"
        :showModal="showModal"
        :mismatchedIngredients="modalData"
        @close-modal="closeModal"
        @confirm-changes="addToStorageFromModal"
      />
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { getCookie } from '../cookieUtils';
  import { jwtDecode } from 'jwt-decode';
  import ListModal from './ListModal.vue'; // Import the ListModal component
  
  export default {
    components: {
      ListModal,
    },
    props: {
      item: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    data() {
      return {
        showModal: false,
        modalData: [], // Data to be passed to the modal
      };
    },
    methods: {
      async moveToStorage() {
        try {
          const token = getCookie('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;
  
          // Fetch storage items
          const response = await axios.get(`/api/storageItem/?id=${username}`);
          const storageItems = response.data;
  
          // Check if the item already exists in storage
          const existingItem = storageItems.find(
            (item) => item.title.toLowerCase() === this.item.title.toLowerCase()
            );
  
          if (existingItem) {
            if (existingItem.quantityUnit === this.item.quantityUnit) {
              const updatedItem = {
                ...existingItem,
                quantity: existingItem.quantity + this.item.quantity,
              };
              
              await axios.put(`/api/storageItem/?userid=${username}&title=${updatedItem.title}`, updatedItem);
              await this.deleteItem();
            } else {
              this.modalData = [
                {
                  ingredient: {
                    quantity: this.item.quantity,
                    quantityUnit: existingItem.quantityUnit,
                    name: this.item.title,
                  },
                },
              ];
              this.showModal = true;
            }
            this.$emit('toStorage', this.item);
          } else {
            const newItem = {
                title: this.item.title,
                quantity: this.item.quantity,
                quantityUnit: this.item.quantityUnit,
            };
            await axios.post(`/api/storageItem/?id=${username}`, newItem);
            await this.deleteItem();

            // Emit the event with the new item
            this.$emit('toStorage', newItem);
          }
        } catch (error) {
          console.error('Error moving item to storage:', error);
        }
      },
      async addToStorageFromModal(mismatchedIngredients) {
        try {
            const token = getCookie('token');
            const decodedToken = jwtDecode(token);
            const username = decodedToken.username;
    
            const ingredient = mismatchedIngredients[0].ingredient;
    
            const response = await axios.get(`/api/storageItem/?id=${username}`);
            const storageItems = response.data;
            
            const existingItem = storageItems.find(
                (item) => item.title.toLowerCase() === this.item.title.toLowerCase()
                );

            const updatedItem = {
                title: ingredient.name,
                quantity: existingItem ? existingItem.quantity + parseFloat(ingredient.quantity) : parseFloat(ingredient.quantity),
                quantityUnit: existingItem.quantityUnit,
            };

          await axios.put(`/api/storageItem/?userid=${username}&title=${updatedItem.title}`, updatedItem);
          await this.deleteItem();
          this.$emit('toStorage', updatedItem);

          this.closeModal();
        } catch (error) {
          console.error('Error adding item to storage from modal:', error);
        }
      },
      closeModal() {
        this.showModal = false;
      },
      async deleteItem() {
        try {
          const token = getCookie('token');
          if (!token) {
            throw new Error('Token not found');
          }
          const decodedToken = jwtDecode(token);
          const username = decodedToken.username;
  
          await axios.delete(`/api/listItem/?userid=${username}&title=${this.item.title}`);
          this.$emit('itemDeleted', this.item.title);
        } catch (error) {
          console.error('Error deleting list item:', error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .list-item-container {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .item-actions i {
    cursor: pointer;
    margin-left: 10px;
    font-size: 20px;
  }
  
  .item-actions i:hover {
    color: #ac8d7e;
  }

  .title-text{
    width: 80%;
  }
  </style>