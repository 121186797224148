<template>
  <div v-if="show" class="progress-indicator">
    <div class="progress-bar" :style="{ width: progress + '%' }"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      progress: 0,
    };
  },
  methods: {
    updateProgress(progress) {
      this.progress = progress;
    },
    showIndicator() {
      this.show = true;
    },
    hideIndicator() {
      this.show = false;
    }
  },
};
</script>

<style scoped>
.progress-indicator {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
}

.progress-bar {
  height: 100%;
  background: #c08c5a;
  border-radius: 4px;
}
</style>