<template>
  <div class="my-space">
    <h2>Mein Bereich</h2>
    <div v-if="user">
      <p>Aktueller Benutzername: <strong>{{ user.newUsername || user.username }}</strong></p>
    </div>
    <div v-if="canChangeUsername" class="form-group">
      <label for="username">Neuer Benutzername</label>
      <input type="text" id="username" v-model="newUsername" @input="checkUsername" required class="form-control" placeholder="Neuen Benutzernamen eingeben">
    </div>
    <div v-else>
      <p>Der Benutzername kann nur alle 6 Monate geändert werden.</p>
    </div>
    <div v-if="usernameExists === true" class="text-danger">
      <i class="bi bi-x-circle-fill"></i> Dieser Benutzername existiert bereits
    </div>
    <div v-if="usernameExists === false" class="text-success">
      <i class="bi bi-check-circle-fill"></i> Benutzername verfügbar
    </div>
    <button v-if="canChangeUsername" @click="changeUsername" :disabled="!newUsername || usernameExists === true" class="btn btn-custom mb-4">Benutzernamen ändern</button>
    <div class="form-group">
      <label for="newPassword">Neues Passwort</label>
      <input type="password" id="newPassword" v-model="newPassword" required class="form-control" placeholder="Neues Passwort eingeben">
      <div v-if="newPassword.length > 0 && !isPasswordValid" class="text-danger validation-message">
        <i class="bi bi-x-circle-fill"></i> Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben und ein Sonderzeichen enthalten.
      </div>
      <div v-if="isPasswordValid && newPassword.length > 0" class="text-success validation-message">
        <i class="bi bi-check-circle-fill"></i> Passwort erfüllt die Anforderungen
      </div>
    </div>
    <button @click="changePassword" :disabled="!newPassword || !isPasswordValid" class="btn btn-custom mb-4">Passwort ändern</button>

    <h3>Meine Rezepte</h3>
    <div v-if="paginatedRecipes.length">
      <div v-for="recipe in paginatedRecipes" :key="recipe.id" class="card mb-3">
        <div class="d-flex justify-content-between align-items-center p-2">
          <p class="card-title mb-0">{{ recipe.title }}</p>
          <div>
            <button @click="editRecipe(recipe.id)" class="btn btn-custom">Bearbeiten</button>
            <button @click="confirmDeleteRecipe(recipe.id)" class="btn btn-danger ms-2"><i class="bi bi-trash2-fill"></i></button>
          </div>
        </div>
      </div>
      <PaginationComponent
        :current-page="currentPage"
        :total-pages="totalPages"
        @page-changed="changePage"
      />
    </div>
    <p v-else>Du hast noch keine Rezepte hochgeladen.</p>

    <button @click="deleteAccount" class="btn btn-danger mt-3">Account löschen</button>

    <!-- Modal -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteModalLabel">Rezept löschen</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Das Rezept und alle Bewertungen werden gelöscht und können nicht wiederhergestellt werden.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
            <button type="button" class="btn btn-danger" @click="deleteRecipe">Löschen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import * as bootstrap from 'bootstrap';
import $ from 'jquery';
import { getCookie, deleteCookie, setCookie } from '../cookieUtils';
import PaginationComponent from '../components/PaginationComponent.vue';
import { jwtDecode } from 'jwt-decode';

export default {
  components: {
    PaginationComponent,
  },
  data() {
    return {
      user: null,
      newUsername: '',
      newPassword: '',
      recipes: [],
      currentPage: 1,
      itemsPerPage: 5,
      recipeIdToDelete: null,
      usernameExists: null,
      canChangeUsername: true
    };
  },
  computed: {
    paginatedRecipes() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.recipes.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.recipes.length / this.itemsPerPage);
    },
    isPasswordValid() {
      return this.validatePassword(this.newPassword);
    }
  },
  mounted() {
    this.fetchCurrentUser();
    this.fetchUserRecipes();
    this.checkIfUsernameChangeAllowed();
  },
  methods: {
    validatePassword(password) {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/;
      return passwordRegex.test(password);
    },
    async checkUsername() {
      if (this.newUsername.trim() === '') {
        this.usernameExists = null;
        return;
      }
      try {
        const response = await axios.get(`/api/checkUsername?username=${this.newUsername}`);
        this.usernameExists = response.data.exists;
      } catch (error) {
        console.error('Error checking username:', error);
        this.usernameExists = null;
      }
      return this.usernameExists;
    },
    async fetchCurrentUser() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`/api/user/?id=${username}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.user = response.data;
        this.checkIfUsernameChangeAllowed();
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    },
    checkIfUsernameChangeAllowed() {
      const lastChangeDate = this.user?.lastUsernameChange || 0;
      const sixMonths = 6 * 30 * 24 * 60 * 60 * 1000; // Six months in milliseconds
      const now = Date.now();
      this.canChangeUsername = (now - lastChangeDate >= sixMonths);
    },
    async fetchUserRecipes() {
      try {
        const token = getCookie('token');
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.get(`/api/recipes/?userId=${username}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.recipes = response.data;
      } catch (error) {
        console.error('Error fetching user recipes:', error);
      }
    },
    async changeUsername() {
      try {
        const token = getCookie('token');
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        const response = await axios.put(
          `/api/user/?id=${username}`,
          {
            newUsername: this.newUsername,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        alert('Benutzername erfolgreich geändert!');

        const newToken = response.data.token;
        deleteCookie('token');
        setCookie('token', newToken, 7);

        this.fetchCurrentUser();
        this.newUsername = '';
      } catch (error) {
        console.error('Error changing username:', error);
      }
    },  async changePassword() {
      try {
        const token = getCookie('token');
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        await axios.put(
          '/api/password',
          {
            username: username,
            newPassword: this.newPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Passwort erfolgreich geändert!');
        this.newPassword = '';
      } catch (error) {
        console.error('Error changing password:', error);
      }
    },
    async deleteAccount() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        await axios.delete(`/api/user/?id=${username}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        deleteCookie('token');
        this.$router.push('/');
      } catch (error) {
        console.error('Error deleting account:', error);
      }
    },
    editRecipe(recipeId) {
      this.$router.push({ name: 'RecipeEdit', params: { id: recipeId } });
    },
    confirmDeleteRecipe(recipeId) {
      this.recipeIdToDelete = recipeId;
      const modalElement = document.getElementById('deleteModal');
      $('body').append(modalElement);
      const modal = new bootstrap.Modal(modalElement, { backdrop: 'static' });
      modal.show();
    },
    async deleteRecipe() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;
        await axios.delete(`/api/recipe/${this.recipeIdToDelete}/?id=${username}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        this.fetchUserRecipes(); // Refresh the recipe list
        this.recipeIdToDelete = null;
        const modal = bootstrap.Modal.getInstance(document.getElementById('deleteModal'));
        modal.hide();
      } catch (error) {
        console.error('Error deleting recipe:', error);
      }
    },
    changePage(page) {
      this.currentPage = page;
    }

  }
};
</script>

<style scoped>
.my-space {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  margin: 20px;
}

h2, h3 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.btn-custom {
  background-color: #ac8d7e;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.btn-custom:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

.card {
  border: 1px solid #ac8d7e;
  border-radius: 5px;
}

.card-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-title {
  margin: 0;
  width: 40%;
}
</style>
``