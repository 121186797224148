<template>
    <div class="modal-overlay" v-if="showModal">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Bitte angeben wie viel zu dem Vorrat hinzugefügt werden soll:</h2>
          <button class="modal-close" @click="closeModal">×</button>
        </div>
        <div class="modal-body">
          <div v-for="(item, index) in mismatchedIngredients" :key="index" class="ingredient-row">
            <div class="input-group">
              <input
                v-model="item.ingredient.quantity"
                type="number"
                class="form-input amount-input"
                placeholder="Menge"
                required
              />
              <input
                v-model="item.ingredient.quantityUnit"
                type="text"
                class="form-input unit-input"
                readonly
              />
              <input
                v-model="item.ingredient.name"
                type="text"
                class="form-input name-input"
                readonly
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="closeModal">Nichts ändern</button>
          <button class="btn btn-primary" @click="confirmChanges">Bestätigen</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showModal: {
        type: Boolean,
        required: true,
      },
      mismatchedIngredients: {
        type: Array,
        required: true,
      },
    },
    methods: {
      closeModal() {
        this.$emit('close-modal');
      },
      confirmChanges() {
        this.$emit('confirm-changes', this.mismatchedIngredients);
        this.closeModal();
      },
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    max-height: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-body {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .ingredient-row {
    margin-bottom: 15px;
  }
  
  .input-group {
    display: flex;
    gap: 10px;
  }
  
  .form-input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .amount-input {
    max-width: 80px;
  }
  
  .unit-input {
    max-width: 80px;
    background-color: #f9f9f9;
  }
  
  .name-input {
    flex-grow: 1;
    background-color: #f9f9f9;
    width: 10%;
  }
  
  .btn {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    transition: background-color 0.3s;
  }
  
  .btn-primary {
    background-color: #ac8d7e;
    color: white;
  }
  
  .btn-primary:hover {
    background-color: #c28a5e;
  }
  
  .btn-secondary {
    background-color: #e0e0e0;
    color: black;
  }
  
  .btn-secondary:hover {
    background-color: #c4c4c4;
  }
  </style>