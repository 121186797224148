<template>
  <div class="add-recipe-container">
    <h2 class="title">{{ isEditMode ? 'Rezept bearbeiten' : 'Rezept erstellen' }}</h2>
    <form class="add-recipe-form" @submit.prevent="saveRecipe">
      <div class="form-group">
        <MethodSelector v-model="selectedMethod" :methods="methods" />
      </div>

      <div class="form-group">
        <label for="title">Titel</label>
        <input id="title" v-model="recipe.title" required class="form-control" />
      </div>

      <div class="form-group meal-servings-line">
        <div class="form-group">
          <label for="meal-type">Art des Gerichts</label>
          <select v-model="recipe.mealType" id="meal-type" class="form-control">
            <option value="" disabled selected>Option</option>
            <option value="Frühstück">Frühstück</option>
            <option value="Mittagessen">Mittagessen</option>
            <option value="Abendessen">Abendessen</option>
            <option value="Dessert">Dessert</option>
            <option value="Snack">Snack</option>
          </select>
        </div>
        <div class="form-group servings-group">
          <label for="servings">Portionen</label>
          <input
            id="servings"
            v-model="recipe.servings"
            class="form-control portions"
            placeholder="Portionen"
          />
        </div>
      </div>

      <div class="form-group">
        <div :class="['field-header', 'd-inline']">
          <label for="images">Bilder</label>

          <div class="existing-images" v-if="existingImages.length">
            <div v-for="(image, index) in existingImages" :key="index" class="image-preview-wrapper">
              <img :src="image.url" class="image-preview" alt="Image Preview" />
              <button type="button" @click="removeImage(index)" class="remove-field-button">-</button>
            </div>
          </div>
        </div>

        <div class="form-group" v-if="existingImages.length < 3">
          <div class="dynamic-field">
            <input ref="fileInput" type="file" @change="onFileChange($event, existingImages.length)" class="form-control file-input" />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="tags">Tags</label>
        <TagSelector v-model="selectedTags" :tags="tags" />
      </div>

      <div class="form-group">
        <div class="field-header">
          <label>Zutaten</label>
          <button type="button" @click="addIngredient" class="add-field-button">+</button>
        </div>
        <div v-for="(ingredient, index) in recipe.ingredients" :key="index" :class="['dynamic-field', { 'first-field': index === 0 }]">
          <input v-model="ingredient.amount" type="number" class="form-control amount-field" placeholder="Menge" min="0" />
          <select v-model="ingredient.unit" class="form-control unit-field">
            <option value="g">g</option>
            <option value="kg">kg</option>
            <option value="ml">ml</option>
            <option value="EL">EL</option>
            <option value="TL">TL</option>
            <option value="Pck">Pck</option>
            <option value="Stck">Stck</option>
            <option value="Prise">Prise</option>
            <option value="etwas">etwas</option>
            <option value="einige">einige</option>
          </select>
          <input v-model="ingredient.name" required class="form-control ingredient-field" placeholder="Zutat" />
          <button v-if="index !== 0" type="button" @click="removeIngredient(index)" class="remove-field-button">-</button>
        </div>
      </div>

      <div class="form-group">
        <div class="field-header">
          <label>Anweisungen</label>
          <button type="button" @click="addInstruction" class="add-field-button">+</button>
        </div>
        <div v-for="(instruction, index) in recipe.instructions" :key="index" :class="['dynamic-field', { 'first-field': index === 0 }]">
          <textarea
            v-model="recipe.instructions[index]"
            required
            class="form-control instruction-field"
            :style="{ height: '100%', minHeight: '50px' }"
          ></textarea>
          <button v-if="index !== 0" type="button" @click="removeInstruction(index)" class="remove-field-button">-</button>
        </div>
      </div>

     <!-- <div class="form-group">
        <privacy-checkbox v-model:checked="privacyPolicyAccepted" />
      </div>-->

      <div class="form-actions">
        <button type="button" class="btn btn-secondary" @click="cancel">Abbrechen</button>
        <!--<button type="submit" class="btn btn-brown" :disabled="!privacyPolicyAccepted">{{ isEditMode ? 'Rezept aktualisieren' : 'Rezept speichern' }}</button>-->
        <button type="submit" class="btn btn-brown" >{{ isEditMode ? 'Rezept aktualisieren' : 'Rezept speichern' }}</button>
      </div>
    </form>
    <ProgressIndicator ref="progressIndicator" />
  </div>
</template>

<script>
import axios from 'axios';
import MethodSelector from './MethodSelector.vue';
import TagSelector from './TagSelector.vue';
//import PrivacyCheckbox from '../components/PrivacyCheckbox.vue';
import imageCompression from 'browser-image-compression';
import ProgressIndicator from '../components/ProgressIndicator.vue';

import { getCookie } from '../cookieUtils';
import { jwtDecode } from 'jwt-decode';

export default {
  name: 'AddRecipe',
  components: {
    MethodSelector,
    TagSelector,
    //PrivacyCheckbox,
    ProgressIndicator
  },
  props: {
    recipeProp: {
      type: Object,
      default: () => ({
        title: '',
        images: [],
        method: [],
        tags: [],
        instructions: [''],
        ingredients: [{ amount: '', unit: 'g', name: '' }],
        mealType: ''
      })
    }
  },
  computed: {
    existingImages() {
      return this.imageFiles.filter(image => image.url);
    }
  },
  data() {
    return {
      recipe: { ...this.recipeProp },
      servings: this.recipeProp.servings || '',
      methods: ['Ofen', 'Heißluftfritteuse', 'Herd'],
      tags: ['Auflauf', 'Salat', 'Gemüse', 'Obst', 'Hähnchen', 'Schwein', 'Vegetarisch', 'Vegan', 'Schnell'],
      selectedMethod: this.recipeProp.method || [],
      selectedTags: this.recipeProp.tags || [],
      imageFiles: this.recipeProp.images.length ? this.recipeProp.images.map(image => ({ existing: true, url: image })) : [{ existing: false, url: null }],
      isEditMode: false,
      privacyPolicyAccepted: false,
      textareaHeights: []
    };
  },
  async created() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      try {
        const response = await axios.get(`/api/recipe/${this.$route.params.id}`);
        this.recipe = response.data;

        this.selectedMethod = this.recipe.method;
        this.selectedTags = this.recipe.tags;

        if (this.recipe.images && this.recipe.images.length > 0) {
          this.imageFiles = await Promise.all(
            this.recipe.images.map(async (imageId) => {
              const imageBlob = await this.fetchImageFromKV(imageId);
              return { existing: true, url: URL.createObjectURL(imageBlob) };
            })
          );
        }
      } catch (error) {
        console.error('Error loading recipe:', error);
      }
    }
  },
  methods: {
    async fetchImageFromKV(kvUrl) {
      const kvImageId = kvUrl.split('kv://')[1]; // Extract the image ID from the URL
      const currentUrl = window.location.origin; // Get the current origin (protocol + hostname + port)
      const response = await fetch(`${currentUrl}/api/kv?id=${kvImageId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch image: ${response.statusText}`);
      }
      return await response.blob();
    },
    onFileChange(e, index) {
      const file = e.target.files[0];
      if (file) {
        const url = URL.createObjectURL(file);
        const imageObject = {
          existing: false,
          url: url,
          file: file,
          mimeType: file.type
        };
        if (typeof index === 'undefined') {
          this.imageFiles.push(imageObject);
        } else {
          this.imageFiles[index] = imageObject;
          if (index === this.imageFiles.length - 1) {
            this.addImageField();
          }
        }
        // Reset the file input field
        this.$refs.fileInput.value = '';
      }
    },
    addImageField() {
      if (this.imageFiles.length < 3) {
        this.imageFiles.push({ existing: false, url: null });
      }
    },
    removeImage(index) {
      this.imageFiles.splice(index, 1);
      this.recipe.images.splice(index, 1);
    },
    addInstruction() {
      this.recipe.instructions.push('');
      this.textareaHeights.push('auto'); // Initialize with 'auto' height
    },
    removeInstruction(index) {
      this.recipe.instructions.splice(index, 1);
      this.textareaHeights.splice(index, 1); // Remove corresponding height
    },
    getTextareaStyle(index) {
      return {
        height: this.textareaHeights[index]
      };
    },
    adjustTextareaHeight(index) {
      this.$nextTick(() => {
        const textareaRef = this.$refs['instruction' + index];
        if (textareaRef && textareaRef[0]) {
          const textarea = textareaRef[0];
          textarea.style.height = 'auto'; // Reset the height to auto
          textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on content
          this.textareaHeights[index] = `${textarea.scrollHeight}px`; // Update the height in the array
        }
      });
    },
    addIngredient() {
      this.recipe.ingredients.push({ amount: '', unit: 'g', name: '' });
    },
    removeIngredient(index) {
      this.recipe.ingredients.splice(index, 1);
    },
    async saveRecipe() {
      try {
        const token = getCookie('token');
        if (!token) {
          throw new Error('Token not found');
        }
        const decodedToken = jwtDecode(token);
        const username = decodedToken.username;

        this.recipe.method = this.selectedMethod;
        this.recipe.tags = this.selectedTags;

        // Filter out empty instructions and ingredients
        this.recipe.instructions = this.recipe.instructions.filter(instruction => instruction.trim() !== '');
        this.recipe.ingredients = this.recipe.ingredients.filter(ingredient => ingredient.name.trim() !== '');

        // Prepare form data
        const formData = new FormData();
        const recipeString = JSON.stringify(this.recipe);
        formData.append('recipe', recipeString);

        // Include both existing images and new images
        for (let i = 0; i < this.imageFiles.length; i++) {
          const fileObj = this.imageFiles[i];
          if (fileObj.existing) {
            formData.append('existingImages', fileObj.url); // Send the URL for existing images
          } else if (fileObj.file) {
            // Compress the image before appending
            const compressedFile = await imageCompression(fileObj.file, {
              maxSizeMB: 1,
              maxWidthOrHeight: 1024,
              useWebWorker: true
            });
            // Directly append the compressed file to formData
            formData.append('images', compressedFile, compressedFile.name);
          }
        }

        const url = this.isEditMode ? `/api/recipe/${this.recipe.id}/?id=${username}` : `/api/recipes/?id=${username}`;
        const method = this.isEditMode ? 'put' : 'post';
        await axios({
          method,
          url,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true // Ensure cookies are sent with the request
        });
        this.$router.push('/');
        this.$emit('recipeAdded');
        //this.$router.go(0);
      } catch (error) {
        console.error('Error saving recipe:', error.message || error);
      }
    },
    cancel() {
      this.$router.push('/');
      this.router.go(0)
    }
  }
};
</script>

<style scoped>
.add-recipe-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.add-recipe-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-control {
  padding: 10px;
  border: 1px solid #ac8d7e;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #ac8d7e;
  outline: none;
}

.btn-brown {
  background-color: #ac8d7e;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-brown:hover {
  background-color: #ac8d7e;
}

.btn-secondary {
  background-color: #ccc;
  color: black;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-secondary:hover {
  background-color: #999;
}

.title {
  margin-bottom: 20px;
  text-align: center;
}

.field-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.field-header label {
  flex-grow: 1;
}

.add-field-button {
  background-color: #ac8d7e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-bottom: 5px;
}

.add-field-button:hover {
  background-color: #ac8d7e;
}

.remove-field-button {
  background-color: #ac8d7e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  margin-left: 10px;
}

.remove-field-button:hover {
  background-color: #ac8d7e;
}

.dynamic-field {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.dynamic-field.first-field {
  margin-left: 10%;
}

.file-input {
  flex-grow: 1;
}

.amount-field {
  width: 40%;
  height: 50px;
}

.unit-field {
  width: 20%;
  height: 50px;
}

.instruction-field {
  height: 50px;
  flex-grow: 1;
}

.ingredient-field {
  flex-grow: 1;
  height: 50px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.existing-images {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.image-preview-wrapper {
  position: relative;
  flex-direction: column;
  align-items: center;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ac8d7e;
  border-radius: 4px;
}

.remove-field-button {
  margin-top: 5px;
  background-color: #ac8d7e;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
  order: -1;
}

.remove-field-button:hover {
  background-color: #ac8d7e;
}
.add-field-button-no-title
{
  margin-left: 92.5%;
}

.meal-servings-line {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Add space between the fields */
}

/* Ensure the form controls are responsive */
.meal-servings-line .form-group {
  flex: 1;
}

.servings-group {
  max-width: 200px; /* Adjust the width as needed */
}

</style>