<template>
  <div class="privacy-checkbox">
    <input type="checkbox" id="privacyPolicy" v-model="isChecked" @change="emitChecked" />
    <label for="privacyPolicy">
      Ich akzeptiere hiermit die 
      <a href="#" @click.prevent="openModal('datenschutz')" style="color: black; font-weight: bold;">Datenschutzerklärung</a>
      und 
      <a href="#" @click.prevent="openModal('agb')" style="color: black; font-weight: bold;">AGB</a>
    </label>
  </div>

  <div v-if="showModal" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <button class="modal-close" @click="closeModal">×</button>
      <iframe v-if="modalContent === 'datenschutz'" src="/datenschutz" frameborder="0"></iframe>
      <iframe v-if="modalContent === 'agb'" src="/agb" frameborder="0"></iframe>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'PrivacyCheckbox',
  data() {
    return {
      isChecked: false,
      showModal: false,
      modalContent: ''
    };
  },
  methods: {
    emitChecked() {
      this.$emit('update:checked', this.isChecked);
    },
    openModal(content) {
      this.modalContent = content;
      this.showModal = true;
      document.body.style.overflow = 'hidden'; // Disable background scroll
    },
    closeModal() {
      this.showModal = false;
      document.body.style.overflow = ''; // Re-enable background scroll
    }
  }
};
</script>
  
<style scoped>
.privacy-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.privacy-checkbox label {
  margin-left: 8px;
  color: #495057;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 80%;
  overflow: auto;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-content iframe {
  width: 100%;
  height: 600px;
  border: none;
}
</style>