<template>
  <div class="container mt-5">
    <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>
    <br>
    <p>Willkommen bei meisterchef.de. Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die Nutzung unserer Webseite. Mit der Nutzung unserer Dienste erklären Sie sich mit diesen Bedingungen einverstanden.</p>
    <br>

    <h3>1. Geltungsbereich</h3>
    <p>Diese AGB gelten für alle Nutzer und Besucher der Webseite meisterchef.de. Sie umfassen die Regeln und Bedingungen, die für die Nutzung unserer Dienste gelten.</p>
    <br>

    <h3>2. Nutzungsbedingungen</h3>
    <p><strong>2.1 Registrierung und Benutzerkonto:</strong> Bestimmte Funktionen unserer Webseite erfordern eine Registrierung. Nutzer müssen genaue und vollständige Angaben machen und sind für die Geheimhaltung ihrer Zugangsdaten verantwortlich.</p>
    <p><strong>2.2 Verantwortlichkeit für Inhalte:</strong> Nutzer können Rezepte, Texte, Bilder, Bewertungen und andere Inhalte (nachfolgend "Inhalte" genannt) auf die Webseite hochladen. Die Nutzer sind allein verantwortlich für die von ihnen hochgeladenen Inhalte. Sie versichern, dass die Inhalte keine Rechte Dritter verletzen und dass sie über alle erforderlichen Rechte an den Inhalten verfügen.</p>
    <br>

    <h3>3. Unzulässige Inhalte</h3>
    <p>Benutzer dürfen keine Inhalte hochladen, die:</p>
    <ul>
      <li>Sexuelle Inhalte: Pornografische, obszöne oder in anderer Weise unangemessene Inhalte.</li>
      <li>Gewaltverherrlichung: Inhalte, die Gewalt verherrlichen oder zu Gewalt aufrufen.</li>
      <li>Diskriminierung: Inhalte, die diskriminierend oder hasserfüllt gegenüber Personen oder Gruppen aufgrund von Rasse, Ethnie, Nationalität, Religion, Geschlecht, sexueller Orientierung oder einer anderen Kategorie sind.</li>
      <li>Illegale Aktivitäten: Inhalte, die illegale Aktivitäten fördern oder darstellen.</li>
      <li>Urheberrechtsverletzungen: Inhalte, die gegen das Urheberrecht oder andere Schutzrechte Dritter verstoßen.</li>
    </ul>
    <br>

    <h3>4. Nutzungsrechte</h3>
    <p>Mit dem Hochladen von Inhalten auf die Webseite räumt der Benutzer meisterchef.de das nicht-exklusive, weltweite, zeitlich unbefristete und lizenzfreie Recht ein, die Inhalte im Rahmen der Webseite zu nutzen, zu veröffentlichen und zu vervielfältigen. Diese Nutzungsrechte bleiben auch nach Beendigung der Mitgliedschaft bestehen.</p>
    <br>

    <h3>5. Haftungsausschluss</h3>
    <p>meisterchef.de übernimmt keine Verantwortung für die von Benutzern hochgeladenen Inhalte. Für die Rechtmäßigkeit, Richtigkeit und Vollständigkeit der Inhalte sind allein die Benutzer verantwortlich.</p>
    <br>

    <h3>6. Sanktionen bei Verstößen</h3>
    <p>Bei Verstößen gegen diese AGB, insbesondere gegen die Regeln zu unzulässigen Inhalten, behält sich meisterchef.de das Recht vor, Inhalte zu löschen, Benutzerkonten zu sperren oder zu löschen und rechtliche Schritte einzuleiten.</p>
    <br>

    <h3>7. Änderung der AGB</h3>
    <p>meisterchef.de behält sich das Recht vor, diese AGB jederzeit zu ändern. Nutzer werden über Änderungen in geeigneter Weise informiert. Die fortgesetzte Nutzung der Webseite nach solchen Änderungen gilt als Zustimmung zu den geänderten Bedingungen.</p>
    <br>

    <h3>8. Schlussbestimmungen</h3>
    <p>Sollte eine Bestimmung dieser AGB unwirksam sein, bleiben die übrigen Bestimmungen davon unberührt. Es gilt das Recht der Bundesrepublik Deutschland. Gerichtsstand ist, soweit gesetzlich zulässig, der Sitz von meisterchef.de.</p>
    <br>
  </div>
</template>

<script>
export default {
  name: 'AGBPage'
}
</script>

<style>
.container {
  padding: 20px;
}

h1, h3 {
  color: #333;
}

p, ul {
  color: #555;
  line-height: 1.6;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}
</style>