import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import AddStorageItem from '../views/AddStorageItem.vue';
import AddRecipe from '../views/AddRecipe.vue';
import AGBPage from '../views/AGBPage.vue';
import DatenschutzPage from '../views/DatenschutzPage.vue';
import ImpressumPage from '../views/ImpressumPage.vue';
import RecipeDetail from '../views/RecipeDetail.vue';
import UserLogin from '../views/UserLogin.vue';
import UserRegister from '../views/UserRegister.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import MySpace from '../views/MySpace.vue'; 
import {getCookie } from '../cookieUtils';

const routes = [
  { path: '/', component: HomePage },
  { path: '/add-storage-item', component: AddStorageItem },
  { path: '/add-recipe', component: AddRecipe },
  { path: '/edit-recipe/:id', component: AddRecipe, name: 'RecipeEdit', props: true },
  { path: '/agb', component: AGBPage },
  { path: '/datenschutz', component: DatenschutzPage },
  { path: '/impressum', component: ImpressumPage },
  { path: '/recipe/:id', component: RecipeDetail, name: 'RecipeDetail' },
  { path: '/login', component: UserLogin, name: 'UserLogin' },
  { path: '/register', component: UserRegister, name: 'UserRegister' },
  { path: '/forgot-password', component: ForgotPassword, name: 'ForgotPassword' },
  { path: '/mine', component: MySpace, name: 'Mine' }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const token = getCookie('token');
  
  if ((to.path === '/add-recipe' || to.path === '/add-storage-item') && !token) {
    next({ name: 'UserLogin' });
  } else {
    next();
  }
});

export default router;