import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { register } from 'swiper/element/bundle';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/custom.css';

register(); // Register Swiper elements

// Scroll to top on route change
router.afterEach(() => {
  window.scrollTo(0, 0);
});

createApp(App).use(router).mount('#app');

// Test