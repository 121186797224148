<template>
  <div class="filter-container d-flex justify-content-between align-items-center">
    <input
      type="text"
      class="form-control search-input no-background"
      placeholder="Titel, Frühstück, Zutat ..."
      v-model="searchQuery"
      @input="emitSearchQuery"
    />
    <div class="sorting-options">
      <select class="form-control no-background" v-model="selectedSort" @change="emitSortOption">
        <option value="newest">Neuste zuerst</option>
        <option value="nameAsc">A bis Z aufsteigend</option>
        <option value="nameDesc">A bis Z absteigend</option>
        <option value="quantityAsc">Menge aufsteigend</option>
        <option value="quantityDesc">Menge absteigend</option>
        <option value="ingredientsDesc">im Vorrat absteigend</option>
      </select>

      <button class="btn btn-brown" @click="showAddDishModal">
        <i class="bi bi-plus"></i>
      </button>
    </div>
  </div>
</template>
  
<script>
  export default {
    props: {
      tags: Array
    },
    data() {
      return {
        selectedTags: [],
        searchQuery: '',
        selectedSort: 'newest' // Default sorting option
      }
    },
    methods: {
      toggleFilter(tag) {
        const index = this.selectedTags.indexOf(tag);
        if (index >= 0) {
          this.selectedTags.splice(index, 1);
        } else {
          this.selectedTags.push(tag);
        }
        this.$emit('filter', this.selectedTags);
      },
      emitSearchQuery() {
        const searchTerms = this.searchQuery.split(',')
          .map(term => term.trim())
          .filter(term => term);
        this.$emit('search', searchTerms);
      },
      emitSortOption() {
        this.$emit('sort', this.selectedSort);
      },
      showAddDishModal() {
        this.$emit('show-add-dish-modal');
      }
    }
  }
</script>
<style scoped>
  .filter-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-input {
    margin-right: 10px;
    border-color: #ac8d7e;
    color: #000;
  }

  .search-input:focus {
    border-color: #ac8d7e;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  .sorting-options {
    display: flex;
    align-items: center;
  }

  .sorting-options .form-control {
    min-width: 50px;
  }

  .btn-brown {
    background-color: #ac8d7e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    margin-left: 10px;
  }

.no-background {
  background-color: transparent;
  color: #000;
  appearance: none;
}

</style>